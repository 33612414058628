<template>
    <div v-if="canRead && loggedIn" class="side-bar" :class="{ hidden: !expanded }">
        <div class="side-bar-body">
            <router-link  class="side-bar-option" :class="{ selected: page === 'home' }" :title="$t('Home')" :to="{ name: 'home' }">
                <div class="side-bar-option-icon"><i class="fas fa-home"></i></div>
                <div class="side-bar-option-text">{{ $t("Home") }}</div>
            </router-link>

            <router-link class="side-bar-option" :class="{ selected: page === 'salamanca24h' }" :title="$t('Salamanca24h')" :to="{ name: 'salamanca24h' }">
                <div class="side-bar-option-icon"><img src="@/assets/salamanca24h.ico"/></div>
                <div class="side-bar-option-text">{{ $t("Salamanca24h") }}</div>
            </router-link>
            <router-link  class="side-bar-option" :class="{ selected: page === 'lagaceta' }" :title="$t('LaGaceta')" :to="{ name: 'lagaceta' }">
                <div class="side-bar-option-icon"><img src="@/assets/lagaceta.ico"/></div>
                <div class="side-bar-option-text">{{ $t("La Gaceta de Salamanca") }}</div>
            </router-link>
            <router-link class="side-bar-option" :class="{ selected: page === 'tribuna' }" :title="$t('TribunaSalamanca')" :to="{ name: 'tribuna' }">
                <div class="side-bar-option-icon"><img src="@/assets/tribuna.jpg"/></div>
                <div class="side-bar-option-text">{{ $t("Tribuna de Salamanca") }}</div>
            </router-link>
            <router-link class="side-bar-option" :class="{ selected: page === 'elespanol' }" :title="$t('ElEspanol')" :to="{ name: 'elespanol' }">
                <div class="side-bar-option-icon"><img src="@/assets/espanol.ico"/></div>
                <div class="side-bar-option-text">{{ $t("El Español") }}</div>
            </router-link>
            <router-link  class="side-bar-option" :class="{ selected: page === 'elnortedecastilla' }" :title="$t('ElNorteDeCastilla')" :to="{ name: 'elnortedecastilla' }">
                <div class="side-bar-option-icon"><img src="@/assets/norte.ico"/></div>
                <div class="side-bar-option-text">{{ $t("El Norte de Castilla") }}</div>
            </router-link>
            <router-link  class="side-bar-option" :class="{ selected: page === 'salamancahoy' }" :title="$t('SalamancaHoy')" :to="{ name: 'salamancahoy' }">
                <div class="side-bar-option-icon"><img src="@/assets/salamancahoy.ico"/></div>
                <div class="side-bar-option-text">{{ $t("Salamanca Hoy") }}</div>
            </router-link>
            <router-link  class="side-bar-option" :class="{ selected: page === 'salamancartvaldia' }" :title="$t('SalamancaRtvAlDia')" :to="{ name: 'salamancartvaldia' }">
                <div class="side-bar-option-icon"><img src="@/assets/rtvaldia.ico"/></div>
                <div class="side-bar-option-text">{{ $t("Salamanca Rtv al Día") }}</div>
            </router-link>
            <router-link  class="side-bar-option" :class="{ selected: page === 'usalus' }" :title="$t('Usalus')" :to="{ name: 'usalus' }">
                <div class="side-bar-option-icon"><img src="@/assets/usalus.ico"/></div>
                <div class="side-bar-option-text">{{ $t("Usalus") }}</div>
            </router-link>
            <router-link  class="side-bar-option" :class="{ selected: page === 'gnews' }" :title="$t('GoogleNews')" :to="{ name: 'gnews' }">
                <div class="side-bar-option-icon"><img src="@/assets/Google_News_icon.svg.png"/></div>
                <div class="side-bar-option-text">{{ $t("Google News") }}</div>
            </router-link>
            <div class="side-bar-separator"></div>      
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "../../utils/v-model";
import { AuthController } from "@/control/auth";

export default defineComponent({
    components: {},
    name: "MenuSideBar",
    emits: ["update:expanded"],
    props: {
        expanded: Boolean,
    },
    setup(props) {
        return {
            expandedStatus: useVModel(props, "expanded"),
        };
    },
    data: function () {
        return {
            page: "",
            canRead: false,
            loggedIn: false,

        };
    },
    methods: {
        updatePage: function () {
            this.page = this.$route ? (this.$route.name as string) : "";
        },

        onAuthChanged: function () {
            this.loggedIn = AuthController.isAuthenticated();
            this.canRead = AuthController.CanRead();
        },
    },
    mounted: function () {
        this.updatePage();
        this.onAuthChanged();
        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
    },
    watch: {
        $route: function () {
            this.updatePage();
        },
    },
});
</script>

<style>
.side-bar-option-icon i {
  font-size: 24px; /* O el tamaño que prefieras */
}

.side-bar-option-icon img {
  width: 24px; /* Ancho fijo */
  height: 24px; /* Altura fija */
  object-fit: cover; /* Mantiene la proporción de aspecto dentro de las dimensiones especificadas */
}

</style>
