// Global events manager

import { ColorThemeName } from "./app-preferences";

type CallbackFunctionVariadic = (...args: any[]) => void;

/**
 * Mapping of App events
 */
export type AppEventsMap = {
    /**
     * Event sent when AuthController changes its status
     */
    "auth-status-changed": () => void;

    /**
     * Event sent when AuthController changes its loading status
     * @param loading True if the auth status is being loaded
     */
    "auth-status-loading": (loading: boolean) => void;

    /**
     * Event sent when an Unauthorized (401) status code is received
     * This may indicate the session has expired
     */
    unauthorized: () => void;

    /**
     * Event sent when a locale is selected
     * @param locale The locale identifier
     */
    "set-locale": (locale: string) => void;

    /**
     * Event sent when a locale is loaded
     * @param locale The locale identifier
     */
    "loaded-locale": (locale: string) => void;

    /**
     * Event send when the router loading status changes
     * @param loading True if loading
     */
    "router-load-state-change": (loading: boolean) => void;

    /**
     * Event sent when the theme changes
     * @param theme The theme name
     */
    "theme-changed": (theme: ColorThemeName) => void;

    /**
     * Event sent to show a snackbar
     * @param message The message
     * @param position The position
     */
    snack: (message: string, position?: "left" | "center" | "right") => void;

    /**
     * Event sent to show a message modal
     * @param title The title
     * @param message The message
     */
    "msg-modal": (title: string, message: string) => void;

    /**
     * Event sent to ask for user confirmation
     * @param options The options
     */
    "ask-confirmation": (options: { title: string; message: string; danger?: boolean; callback: () => void }) => void;

    /**
     * Show profile modal
     * @param uid User ID
     */
    "show-profile": (uid: string) => void;
};

/**
 * Global events manager
 */
export class AppEvents {
    public static events: { [key: string]: CallbackFunctionVariadic[] } = {};

    /**
     * Listens for global event
     * @param eventName The event name
     * @param handler The event handler
     */
    public static AddEventListener<K extends keyof AppEventsMap>(eventName: K, handler: AppEventsMap[K]) {
        if (!AppEvents.events[eventName]) {
            AppEvents.events[eventName] = [];
        }
        AppEvents.events[eventName].push(handler);
    }

    /**
     * Emits global event
     * @param eventName The event name
     * @param args The event arguments
     */
    public static Emit<K extends keyof AppEventsMap>(eventName: K, ...args: Parameters<AppEventsMap[K]>) {
        if (AppEvents.events[eventName]) {
            for (const handler of AppEvents.events[eventName]) {
                try {
                    handler(...args);
                } catch (ex) {
                    console.error(ex);
                }
            }
        }
    }

    /**
     * Removes event listener
     * @param eventName The event name
     * @param handler The event handler
     */
    public static RemoveEventListener<K extends keyof AppEventsMap>(eventName: K, handler: AppEventsMap[K]) {
        if (!AppEvents.events[eventName]) {
            return;
        }
        const i = AppEvents.events[eventName].indexOf(handler);
        if (i >= 0) {
            AppEvents.events[eventName].splice(i, 1);
            if (AppEvents.events[eventName].length === 0) {
                delete AppEvents.events[eventName];
            }
        }
    }
}
