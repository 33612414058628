// Routes

"use strict";

import { createRouter, createWebHistory, Router, RouteRecordRaw } from "vue-router";
import { Timeouts } from "./utils/timeout";
import { AppEvents } from "./control/app-events";

// Router
// https://router.vuejs.org/guide/#javascript

// List of forbidden routes:
//
//    - /api/*  - This is reserved for the API
//    - /static/* - This is reserved for static assets
//    - /webhooks*/ - Reserved for webhooks

const routes: (RouteRecordRaw & {
    meta?: {
        /**
         * Set it to true for sticky sidebar
         */
        sidebarSticky?: boolean;
    };
})[] = [
    /* General / Home */

    {
        name: "home",
        path: "/",
        component: () => import("@/components/routes/HomePage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "salamanca24h",
        path: "/salamanca24h",
        component: () => import("@/components/routes/Salamanca24hPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    {
        name: "lagaceta",
        path: "/lagaceta",
        component: () => import("@/components/routes/LaGacetaPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    {
        name: "tribuna",
        path: "/tribuna",
        component: () => import("@/components/routes/TribunaPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    {
        name: "elespanol",
        path: "/elespanol",
        component: () => import("@/components/routes/ElEspanolPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    {
        name: "elnortedecastilla",
        path: "/elnortedecastilla",
        component: () => import("@/components/routes/ElNorteDeCastillaPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    {
        name: "salamancahoy",
        path: "/salamancahoy",
        component: () => import("@/components/routes/SalamancaHoyPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    {
        name: "salamancartvaldia",
        path: "/salamancartvaldia",
        component: () => import("@/components/routes/SalamancaRtvAlDiaPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    {
        name: "usalus",
        path: "/usalus",
        component: () => import("@/components/routes/UsalusPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    {
        name: "gnews",
        path: "/gnews",
        component: () => import("@/components/routes/GNewsPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "about",
        path: "/about",
        component: () => import("@/components/routes/AboutPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "terms",
        path: "/terms",
        component: () => import("@/components/routes/TermsOfUsePage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "cookies",
        path: "/cookies",
        component: () => import("@/components/routes/CookiePolicyPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "privacy",
        path: "/privacy",
        component: () => import("@/components/routes/PrivacyPolicyPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    /* Auth */

    {
        name: "login",
        path: "/login",
        component: () => import("@/components/routes/auth/LoginPage.vue"),
    },

    {
        name: "tfa-login",
        path: "/login/tfa",
        component: () => import("@/components/routes/auth/TwoFactorLoginPage.vue"),
    },

    {
        name: "signup",
        path: "/signup",
        component: () => import("@/components/routes/auth/SignupPage.vue"),
    },
    {
        name: "signup-success",
        path: "/signup/success",
        component: () => import("@/components/routes/auth/SignupSuccessPage.vue"),
    },

    {
        name: "forgot-password",
        path: "/password/forgot",
        component: () => import("@/components/routes/auth/ForgotPasswordPage.vue"),
    },
    {
        name: "reset-password",
        path: "/password/reset/:uid/:token",
        component: () => import("@/components/routes/auth/ResetPasswordPage.vue"),
    },

    {
        name: "verify-email",
        path: "/email/verify/:uid/:token",
        component: () => import("@/components/routes/auth/EmailVerifyPage.vue"),
    },

    {
        name: "tp-login",
        path: "/login/tp/:service",
        component: () => import("@/components/routes/auth/ThirdPartyLogin.vue"),
    },

    {
        name: "tp-signup",
        path: "/signup/tp",
        component: () => import("@/components/routes/auth/ThirdPartySignupPage.vue"),
    },

    /* Profile */

    {
        name: "profile",
        path: "/user/:username",
        component: () => import("@/components/routes/profile/ProfilePage.vue"),
    },

    /* Account */

    {
        name: "account-settings",
        path: "/account-settings",
        component: () => import("@/components/routes/account/AccountSettingsPage.vue"),
    },

    /* Admin */

    {
        name: "admin",
        path: "/admin",
        component: () => import("@/components/routes/admin/AdministrationPage.vue"),
    },

    {
        name: "admin-user",
        path: "/admin/users/:id",
        component: () => import("@/components/routes/admin/UserPage.vue"),
    },

    /* Salamanca24h */
    {
        name: "salamanca24h-info",
        path: "/salamanca24h/:id",
        component: () => import("@/components/routes/Salamanca24hNewPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
     /* La Gaceta */
     {
        name: "lagaceta-info",
        path: "/lagaceta/:id",
        component: () => import("@/components/routes/LaGacetaNewPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
     /* La Tribuna */
     {
        name: "tribuna-info",
        path: "/tribuna/:id",
        component: () => import("@/components/routes/TribunaNewPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
     /* El Español */
     {
        name: "elespanol-info",
        path: "/elespanol/:id",
        component: () => import("@/components/routes/ElEspanolNewPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    /* El Norte de Castilla */
    {
        name: "elnortedecastilla-info",
        path: "/elnortedecastilla/:id",
        component: () => import("@/components/routes/ElNorteDeCastillaNewPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    ,
    /* Salamanca Hoy */
    {
        name: "salamancahoy-info",
        path: "/salamancahoy/:id",
        component: () => import("@/components/routes/SalamancaHoyNewPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    /* Salamanca Hoy */
    {
        name: "salamancartvaldia-info",
        path: "/salamancartvaldia/:id",
        component: () => import("@/components/routes/SalamancaRtvAlDiaNewPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    /* usalus */
    {
        name: "usalus-info",
        path: "/usalus/:id",
        component: () => import("@/components/routes/UsalusNewPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    /* Default */

    {
        path: "/:catchAll(.*)",
        component: () => import("@/components/routes/NotFoundPage.vue"),
    },
];

export function makeApplicationRouter(): Router {
    const router = createRouter({
        // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
        history: createWebHistory(),
        routes, // short for `routes: routes`
    });

    router.beforeEach(() => {
        Timeouts.Set("router-load-state", 300, () => {
            AppEvents.Emit("router-load-state-change", true);
        });
    });

    router.afterEach(() => {
        Timeouts.Abort("router-load-state");
        AppEvents.Emit("router-load-state-change", false);
    });

    return router;
}
